<template>
  <div id="venueVehicle">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column label="车牌号" align="center">
          <template #default="scope">
            <span class="plateNum" @click="goToPage(scope.row.plateNum)">{{
              scope.row.plateNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="vehicleType" label="车辆类型" align="center">
        </el-table-column>
        <el-table-column prop="arrivalTime" label="入场时间" align="center">
        </el-table-column>
        <el-table-column prop="channelName" label="入场设备" align="center">
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="forcedOut(scope.row.parkingRecordId)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-venueVehicleTab-forcedOut'
                )
              "
              >强制出场
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    goToPage(plateNum) {
      this.$router.push({
        name: "parkingRecord",
        params: {
          plateNum,
        },
      });
    },
    forcedOut(parkingRecordId) {
      this.$confirm("此操作将强制出场, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await this.$http.post(`/parking/group/forced/out`, {
          parkingRecordId,
        });
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/parking/group/vehicle", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
          parkingLotId: this.$route.params.id,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.arrivalTime = this.$moment(item.arrivalTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.plateNum = item.plateNum ?? "无牌车";
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#venueVehicle {
  .plateNum {
    cursor: pointer;
  }
}
</style>
